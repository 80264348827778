import {Injectable} from '@angular/core';
import { MxGraphGlobalStoreService, IProjectState } from '../mxgraph-global.store';
import {Subscription} from 'rxjs';

export enum KeyCode {
  ARROW_UP = 38,
  ARROW_LEFT = 37,
  ARROW_RIGHT = 39,
  ARROW_DOWM = 40,
  DELETE_KEY = 46,
}

@Injectable({
  providedIn: 'root'
})

export class KeyEventService {
  constructor(private globals: MxGraphGlobalStoreService){}

  getCurrentGeometry(graph: any): any {
    let currentGeometry = graph.getSelectionCell().getGeometry();
    currentGeometry = currentGeometry.clone();
    return currentGeometry;
  }

  getEdgeTerminalPoint(cell) {
    return Object.assign({
      source: cell.geometry.getTerminalPoint(true),
      target: cell.geometry.getTerminalPoint(false),
    }, null);
  }

  removeCell(graph) {
    graph.removeCells();
  }

  arrowMove(graph, shiftHold, canvasBounds, keyCode) {
    const geo = this.getCurrentGeometry(graph);
    const selectCell = graph.getSelectionCell();
    if (this.checkTypeFigure(selectCell) && selectCell.geometry.getTerminalPoint(true) && selectCell.geometry.getTerminalPoint(false)) {
      const terminalPoint = this.getEdgeTerminalPoint(graph.getSelectionCell());
      graph.getModel().setGeometry(selectCell, geo);
      const cellGeo = graph.getSelectionCell().geometry;

      switch (keyCode) {
        case KeyCode.ARROW_UP:
          cellGeo.setTerminalPoint(new mxPoint(terminalPoint.source.x, terminalPoint.source.y - (shiftHold ? 20 : 10)), true);
          cellGeo.setTerminalPoint(new mxPoint(terminalPoint.target.x, terminalPoint.target.y - (shiftHold ? 20 : 10)), false);
          break;
        case KeyCode.ARROW_DOWM:
          cellGeo.setTerminalPoint(new mxPoint(terminalPoint.source.x, terminalPoint.source.y + (shiftHold ? 20 : 10)), true);
          cellGeo.setTerminalPoint(new mxPoint(terminalPoint.target.x, terminalPoint.target.y + (shiftHold ? 20 : 10)), false);
          break;
        case KeyCode.ARROW_LEFT:
          cellGeo.setTerminalPoint(new mxPoint(terminalPoint.source.x - (shiftHold ? 20 : 10), terminalPoint.source.y), true);
          cellGeo.setTerminalPoint(new mxPoint(terminalPoint.target.x - (shiftHold ? 20 : 10), terminalPoint.target.y), false);
          break;
        case KeyCode.ARROW_RIGHT:
          cellGeo.setTerminalPoint(new mxPoint(terminalPoint.source.x + (shiftHold ? 20 : 10), terminalPoint.source.y), true);
          cellGeo.setTerminalPoint(new mxPoint(terminalPoint.target.x + (shiftHold ? 20 : 10), terminalPoint.target.y), false);
          break;
      }
    } else {
      switch (keyCode) {
        case KeyCode.ARROW_UP:
          geo.y -= shiftHold ? 10 : 20;
          break;
        case KeyCode.ARROW_DOWM:
          geo.y += shiftHold ? 10 : 20;
          break;
        case KeyCode.ARROW_LEFT:
          geo.x -= shiftHold ? 10 : 20;
          break;
        case KeyCode.ARROW_RIGHT:
          geo.x += shiftHold ? 10 : 20;
          break;
        }
      graph.getModel().setGeometry(selectCell , geo);
    }
  }

  indexChange(graph, keyCode) {
    switch (keyCode) {
      case KeyCode.ARROW_UP:
        graph.getModel().add(graph.getDefaultParent(), graph.getSelectionCell(), graph.getChildVertices(graph.getDefaultParent()).length);
        break;
      case KeyCode.ARROW_DOWM:
        graph.getModel().add(graph.getDefaultParent(), graph.getSelectionCell(), 0);
        break;
    }
  }

  checkTypeFigure(cell) {
    return cell.isEdge();
  }


  public keyEventControls(graph, keyCode, shiftHold, ctrlHold, canvasBounds) {

    if (keyCode === KeyCode.DELETE_KEY) this.removeCell(graph);

    if (ctrlHold &&
        (keyCode === KeyCode.ARROW_UP ||
        keyCode === KeyCode.ARROW_DOWM)) this.indexChange(graph, keyCode);

    if ((keyCode === KeyCode.ARROW_UP ||
        keyCode === KeyCode.ARROW_DOWM ||
        keyCode === KeyCode.ARROW_LEFT ||
        keyCode === KeyCode.ARROW_RIGHT) && !ctrlHold) this.arrowMove(graph, shiftHold, canvasBounds, keyCode);


  }
}
