import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { EventEmitterService } from 'src/app/services/event-emmiter.service';
import {EventEmitterType} from '../../interfaces/event-emitter.interface';
import {ActivatedRoute, Router} from "@angular/router";
import {MxGraphGlobalStoreService} from "../../mxgraph-global.store";
@Component({
  selector: 'app-main-layout',
  templateUrl : './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {
  @ViewChild('colorPickContainer', {static: false}) colorPickContainer: ElementRef;

  @HostListener ('click', ['$event']) onClick(e: MouseEvent) {
    if (!this.colorPickContainer.nativeElement.contains(e.target)) this.eventEmitterService.invokeComponentAction(EventEmitterType.OUTSIDE_CLICK);
  }
  constructor(private eventEmitterService: EventEmitterService,
              private activatedRoute: ActivatedRoute,
              private globals: MxGraphGlobalStoreService) { }

  ngOnInit() {
    const vh = window.innerHeight * 0.01;
    (document.querySelector('.wrapper') as HTMLElement).style.setProperty('--vh', `${vh}px`);
  }

  get graphReady() {
    return this.globals.state.graphReady;
  }
}
