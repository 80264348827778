export enum EventEmitterType {
  GRIDS = 'grids',
  UNDO = 'undo',
  POSITION = 'positions',
  BG = 'bg',
  STYLES = 'styles',
  OPEN_SETTING = 'openSetting',
  OUTSIDE_CLICK = 'outside-click',
  FORWARD = 'forward',
  BACK = 'back',
  REDRAW_GRID = 'redrawGrid',
  CLEAR = 'clear',
  ZOOM  = 'zoom',
  CREATE_NEW = 'createNew'
}
