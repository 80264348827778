import {IArrowIcon} from "../interfaces/grahp-cell.interfaces";
import {CreateFigureService} from '../services/create-figure.service';

export const arrowRightStylesIcons: IArrowIcon[] = [{
    url: './assets/images/arrow-styles/stroke-line.svg',
    style: {
      startArrow: 'startArrow=none',
    },
  }, {
    url: './assets/images/arrow-styles/arrow-style-right-1.svg',
    style: {
      startArrow: 'startArrow=classicThin',
    },
  }, {
    url: './assets/images/arrow-styles/arrow-style-right-2.svg',
    style: {
      startArrow: 'startArrow=block',
      startFill: 'startFill=1',
    },
  }, {
    url: './assets/images/arrow-styles/arrow-style-right-3.svg',
    style: {
      startArrow: 'startArrow=block',
      startFill: 'startFill=0',
    },
  }, {
    url: './assets/images/arrow-styles/arrow-style-right-4.svg',
    style: {
      startArrow: 'startArrow=open',
      startFill: 'startFill=1',
    },
  }, {
    url: './assets/images/arrow-styles/arrow-style-right-5.svg',
    style: {
      startArrow: 'startArrow=oval',
      startFill: 'startFill=1',
    },
  }, {
    url: './assets/images/arrow-styles/arrow-style-right-6.svg',
    style: {
      startArrow: 'startArrow=oval',
      startFill: 'startFill=0',
    },
  }, {
    url: './assets/images/arrow-styles/arrow-style-right-7.svg',
    style: {
      startArrow: 'startArrow=diamond',
      startFill: 'startFill=1',
    },
  }, {
    url: './assets/images/arrow-styles/arrow-style-right-8.svg',
    style: {
      startArrow: 'startArrow=diamond',
      startFill: 'startFill=0',
    },
  },
];

export const arrowLeftStylesIcons: IArrowIcon[] = [{
    url: './assets/images/arrow-styles/stroke-line.svg',
    style: {
      endArrow: 'endArrow=none',
    }
  }, {
    url: './assets/images/arrow-styles/arrow-style-left-1.svg',
    style: {
      endArrow: 'endArrow=classicThin',
    }
  }, {
    url: './assets/images/arrow-styles/arrow-style-left-2.svg',
    style: {
      endArrow: 'endArrow=block',
      endFill: 'endFill=1',
    }
  }, {
    url: './assets/images/arrow-styles/arrow-style-left-3.svg',
    style: {
      endArrow: 'endArrow=block',
      endFill: 'endFill=0',
    }

  }, {
    url: './assets/images/arrow-styles/arrow-style-left-4.svg',
    style: {
      endArrow: 'endArrow=open',
      endFill: 'endFill=0',
    }

  }, {
    url: './assets/images/arrow-styles/arrow-style-left-5.svg',
    style: {
      endArrow: 'endArrow=oval',
      endFill: 'endFill=1',
    }

  }, {
    url: './assets/images/arrow-styles/arrow-style-left-6.svg',
    style: {
      endArrow: 'endArrow=oval',
      endFill: 'endFill=0',
    }

  }, {
    url: './assets/images/arrow-styles/arrow-style-left-7.svg',
    style: {
      endArrow: 'endArrow=diamond',
      endFill: 'endFill=1',
    }

  }, {
    url: './assets/images/arrow-styles/arrow-style-left-8.svg',
    style: {
      endArrow: 'endArrow=diamond',
      endFill: 'endFill=0',
    }
  },
];

export const arrowLineStyle: any = [{
    name: 'default',
    url: './assets/images/arrow-styles/stroke-line.svg',
    style: {
      line: 'default',
      rounded: 'rounded=0',
      dashed: 'dashed=0',
      dashPattern: 'dashPattern=0'
    }
  }, {
    name: 'dashed',
    url: './assets/images/arrow-styles/stroke-dash.svg',
    style: {
      line: 'dashed',
      dashed: 'dashed=1',
      rounded: 'rounded=0',
      dashPattern: 'dashPattern=5 2',
    }
  }, {
    name: 'dotted',
    url: './assets/images/arrow-styles/stroke-dot.svg',
    style: {
      line: 'dotted',
      rounded: 'rounded=1',
      dashed: 'dashed=1',
      dashPattern: 'dashPattern=0.7 1.7',
    }
  },
];
