import {Injectable} from '@angular/core';
import {MxGraphGlobalStoreService} from '../mxgraph-global.store';

declare var mxUndoManager: any;
@Injectable({
  providedIn: 'root'
})

export class HistoryService {
  constructor(private globals: MxGraphGlobalStoreService) {}

  state: any;

  createHistoryManager() {
    this.state = new mxUndoManager();
  }

  saveHistory(history) {
    this.state.undoableEditHappened(history);
  }

  undo() {
    this.state.undo();
  }

  redo() {
    this.state.redo();
  }
}
