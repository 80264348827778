import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IProjectState, MxGraphGlobalStoreService} from '../../mxgraph-global.store';
import {Subscription} from 'rxjs';
import {MatSidenav} from '@angular/material';
import {DataCodecService} from '../../services/data-codec.service';
import {UploadResourceService} from '../../resources/upload.resource';
import {EventEmitterService} from '../../services/event-emmiter.service';
import {SideNavsService} from '../../services/side-navs.service';
import {EventEmitterType} from '../../interfaces/event-emitter.interface';
import {HistoryService} from '../../services/history.service';
import {ZoomControlService} from '../../services/zoom-control.service';
import {CreateFigureService} from '../../services/create-figure.service';
import {BgImageType, ZoomValue} from '../../interfaces/zoom-control.interface';
import {GraphCellId} from '../../interfaces/grahp-cell.interfaces';

declare var mxGraphView: any;
declare var mxUndoManager: any;

@Component({
  selector: 'app-aside-nav',
  templateUrl: './aside-nav.component.html',
  styleUrls: ['./aside-nav.component.scss']
})
export class AsideNavComponentComponent implements OnInit, AfterViewInit, OnDestroy {

  subs: Subscription[] = [];
  graphs;
  parent;
  selected;
  prevNav;
  canvas: any;
  activeTabName: string;
  bgTypes = BgImageType;
  board: HTMLElement;
  graphTranslate: any;
  cellId = GraphCellId;
  touchScaling: boolean = false;
  evCache: any[] = [];
  prevDiff: number = 1;
  isFirstElement = true;
  mousePageX: number = 0;
  mousePageY: number = 0;
  dragActive: boolean = false;
  dragPointer: any;
  figureType: GraphCellId;

  @ViewChild('fileUploadImg', {static: false}) fileInput: ElementRef;
  constructor(private globals: MxGraphGlobalStoreService,
              private dataCodecService: DataCodecService,
              private http: HttpClient,
              private uploadResource: UploadResourceService,
              private eventEmitterService: EventEmitterService,
              private sideNavsService: SideNavsService,
              private historyService: HistoryService,
              private zoomControlService: ZoomControlService,
              private createFigureService: CreateFigureService) { }

 async ngOnInit() {
    this.subs.push(
      this.globals.state$.subscribe((state: IProjectState) => {
        this.graphs = state.graphs;
        this.parent = state.parent;
        this.canvas = state.canvasEL;
        this.selected = state.selectedValue;
      }),

      this.eventEmitterService.emitter.subscribe((type) => {
        switch (type) {
          case EventEmitterType.REDRAW_GRID:
          case EventEmitterType.BG:
            this.changeBgImage(this.localGraph.graphBg, {onLoad: true, file: this.localGraph.fileUplad});
            break;
          case EventEmitterType.CLEAR:
          case EventEmitterType.ZOOM:
            this.graphTranslate = {...this.localGraph.graph.view.getBackgroundPageBounds()};
            break;
        }
      }),
    );
    // subscribe graph mouseEvent
    this.localGraph.graph.addMouseListener({
       mouseMove: (sender, e) => {
         this.dragOnMouseMove(e);
       },
       mouseDown: () => {},
       mouseUp: () => {}
     });

    this.graphTranslate = {...this.localGraph.graph.view.getBackgroundPageBounds()};

    this.localGraph.graph.panningHandler.addListener(mxEvent.PAN_END, () => {
      this.graphTranslate = {...this.localGraph.graph.view.getBackgroundPageBounds()};
    });


   // remove drag element
    this.localGraph.graph.getModel().remove(this.localGraph.graph.getModel().getCell('drag'));
  }

  ngAfterViewInit(): void {
    this.board = document.querySelector('.board');
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.watchTouchZoom();
    }

    this.changeBgImage(this.localGraph.graphBg, {onLoad: true, file: this.localGraph.fileUplad});
    this.board.addEventListener('mouseup', () => this.onMouseUp(), false);
  }

  watchTouchZoom() {
    (this.board as any).onpointerdown = this.touchDownScale.bind(this);
    (this.board as any).onpointermove = this.touchMovieScale.bind(this);

    (this.board as any).onpointerup = this.touchUpScale.bind(this);
    (this.board as any).onpointercancel = this.touchUpScale.bind(this);
    (this.board as any).onpointerout = this.touchUpScale.bind(this);
    (this.board as any).onpointerleave = this.touchUpScale.bind(this);
  }

  touchDownScale(ev) {
    if (ev) {
      this.evCache.push(ev);
    }
  }

  touchMovieScale(ev) {
    for (let i = 0; i < this.evCache.length; i++) {
      if (ev.pointerId === this.evCache[i].pointerId) {
        this.evCache[i] = ev;
        break;
      }
    }
    if (this.evCache.length === 2) {
      const curDiff = Math.abs(this.evCache[0].clientX - this.evCache[1].clientX);
      this.localGraph.graph.getModel().beginUpdate();
      try {
        if (this.prevDiff > 0) {
          const factor = curDiff > this.prevDiff ? ZoomValue.ZOOM_MINUS : ZoomValue.ZOOM_PLUS;
          this.zoomControlService.onZooming(factor, this.localGraph.graph);
          this.zoomControlService.scaleCanvasBg(this.localGraph, factor, this.canvas);
        }
      } finally {
        this.localGraph.graphZoom = this.zoomControlService.getCurrentZoomValue(this.localGraph.graph);
        this.localGraph.graph.getModel().endUpdate();
      }
      this.prevDiff = curDiff;
    }
  }

  touchUpScale(ev) {
    this.clearEvCache(ev);
    if (this.evCache.length < 2) {
      this.prevDiff = -1;
    }
  }

  clearEvCache(ev) {
    for (let i = 0; i < this.evCache.length; i++) {
      if (this.evCache[i].pointerId === ev.pointerId) {
        this.evCache.splice(i, 1);
        break;
      }
    }
  }

  get localGraph(): any {
    return this.globals.state.graphs.find((graph) => graph.graphName === this.selected);
  }

  set localGraph(graph) {
    this.globals.saveGraphItem(graph);
  }

  get activeOption(): any {
    return this.globals.state.selectedValue;
  }

  onClose(name) {
    if (this.activeTabName === name) { this.activeTabName = ''; }
  }

  setActiveSideNav(sidenav: MatSidenav, name: string) {
    if (!this.prevNav) {
      this.prevNav = sidenav;
    } else if (this.prevNav === sidenav) {
      this.prevNav.close();
      this.prevNav = false;
      return;
    } else {
      this.prevNav.close();
      this.prevNav = sidenav;
    }
    sidenav.open();
    this.sideNavsService.setSidenav(sidenav);
    this.activeTabName = name;
  }

  checkActiveTabName(name): boolean {
    return this.activeTabName === name;
  }

  canvasDraw(type) {
    this.redrawGrid(this.canvas, this.localGraph.graph, type);
  }

  clearCanvas(ctx) {
    const boardRect = this.board.getBoundingClientRect();
    this.canvas.setAttribute('width', boardRect.width);
    this.canvas.setAttribute('height', boardRect.height);

    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  undoCanvasState(clone) {
    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    context.drawImage(clone, 0, 0);
  }

  redrawGrid(canvas, graph, gridType) {
    let s = 0,
      gs = 0,
      tr = new mxPoint(null, null),
      w = 0,
      h = 0;
    const ctx = canvas.getContext('2d');
    const el: HTMLElement = document.getElementById('board');

    const bounds = graph.getGraphBounds();
    const width = Math.max(bounds.x + bounds.width, el.clientWidth);
    const height = Math.max(bounds.y + bounds.height, el.clientHeight);
    const sizeChanged = width !== w || height !== h;

    if (graph.view.scale !== s || graph.view.translate.x !== tr.x || graph.view.translate.y !== tr.y ||
      gs !== graph.gridSize || sizeChanged) {
      tr = graph.view.translate.clone();
      s = graph.view.scale;
      gs = graph.gridSize;
      w = width;
      h = height;

      // Clears the background if required
      if (!sizeChanged) {
        ctx.clearRect(0, 0, w, h);
      } else {
        canvas.setAttribute('width', w.toString());
        canvas.setAttribute('height', h.toString());
      }

      switch (gridType) {
        case BgImageType.SIMPLE_GRID:
          const tx = tr.x * s;
          const ty = tr.y * s;

          // Sets the distance of the grid lines in pixels

          const minStepping = gs + 10;
          const stepping = minStepping * s;

          // if (stepping < minStepping) {
          //   var count = Math.round(Math.ceil(minStepping / stepping) / 2) * 2;
          //   stepping = count * stepping;
          // }

          const xs = Math.floor((0 - tx) / stepping) * stepping + tx;
          let xe = Math.ceil(w / stepping) * stepping;
          const ys = Math.floor((0 - ty) / stepping) * stepping + ty;
          let ye = Math.ceil(h / stepping) * stepping;

          xe += Math.ceil(stepping);
          ye += Math.ceil(stepping);

          const ixs = Math.round(xs);
          const ixe = Math.round(xe);
          const iys = Math.round(ys);
          const iye = Math.round(ye);

          // Draws the actual grid
          ctx.strokeStyle = 'green';
          ctx.beginPath();

          for (let x = xs; x <= xe; x += stepping) {
            x = Math.round((x - tx) / stepping) * stepping + tx;
            const ix = Math.round(x);

            ctx.moveTo(ix + 0.5, iys + 0.5);
            ctx.lineTo(ix + 0.5, iye + 0.5);
          }

          for (let y = ys; y <= ye; y += stepping) {
            y = Math.round((y - ty) / stepping) * stepping + ty;
            const iy = Math.round(y);

            ctx.moveTo(ixs + 0.5, iy + 0.5);
            ctx.lineTo(ixe + 0.5, iy + 0.5);
          }

          ctx.closePath();
          ctx.stroke();
          break;
        case BgImageType.CHESS_BOARD:
          w = el.clientWidth;
          h = el.clientHeight;
          canvas.setAttribute('width', Math.floor(w * s));
          canvas.setAttribute('height', Math.floor(h * s));
          canvas.setAttribute('style', 'position: absolute; top: 50%; left: 50%; z-index: -1; transform: translate(-50%, -50%);');

          for (let i = 0; i < h / 75; i++) {
            for (let j = 0; j < w / 75; j++) {
              ctx.beginPath();
              ctx.fillStyle = ['#eeeeee', '#5b6266'][(i + j) % 2];
              if (s <= 1) {
                ctx.fillRect(j * 75 * s, i * 75 * s, w * s, h * s);
              } else {
                ctx.fillRect(j * 75 * s, i * 75 * s, w * s, h * s);
              }
              ctx.closePath();
            }
          }
          break;
      }
    }
  }


  ungroupObjects() {
    const cells =  this.localGraph.graph.getSelectionCells();

    this.localGraph.graph.getModel().beginUpdate();
    try {
      for (const cell of cells) {
        if (cell.typeOfFigure === GraphCellId.GROUP_OBJECT) {
          this.localGraph.graph.ungroupCells(cells);
        }
      }
    } finally {
      this.localGraph.graph.getModel().endUpdate();
    }
  }

  dragFigureOnCreate(type, event) {
    this.dragActive = true;
    this.figureType = type;
    this.dragPointer = this.createFigureService.createDragPointer(this.localGraph);
  }

  onMouseUp() {
    if (this.dragActive) {
      const mouseСoordinates = {
        x: this.mousePageX,
        y: this.mousePageY,
      };

      this.createFigureService.createFigure({
        type: this.figureType,
        localGraph: this.localGraph,
        graphTranslate: null,
        isFirstElement: false,
        isDragCreate: true,
        mouseСoordinates
      });
      this.dragActive = false;
    }

    this.localGraph.graph.getModel().remove(this.dragPointer);
    this.historyService.state.clear();
  }

  dragOnMouseMove(event) {
    this.mousePageX = (event.getGraphX() - this.localGraph.graph.view.getBackgroundPageBounds().x) / this.localGraph.graphZoom;
    this.mousePageY = (event.getGraphY() - this.localGraph.graph.view.getBackgroundPageBounds().y) / this.localGraph.graphZoom;


    if (this.dragPointer && this.dragActive) {
      this.dragPointer.setVisible(true);
      this.changeDragPinterGeometry();
    }
  }

  changeDragPinterGeometry() {
    let currentGeometry = this.dragPointer.getGeometry();
    console.log(currentGeometry)
    currentGeometry = currentGeometry.clone();
    currentGeometry.x = this.mousePageX;
    currentGeometry.y = this.mousePageY;
    setTimeout(() => {
      this.localGraph.graph.getModel().setGeometry(this.dragPointer, currentGeometry);
    }, 100);
  }

 async changeBgImage(type, options: {onLoad?: boolean, file?: FileList} = {}) {
    const {onLoad = false, file = null} = options;
    const ctx = (this.canvas as HTMLCanvasElement).getContext('2d');
    const canvasClone = this.cloneCanvas(this.canvas, type);
    const prevImg = this.localGraph.graph.getBackgroundImage();
    this.clearCanvas(ctx);
    this.localGraph.canvasZoom = !onLoad ? this.localGraph.canvasZoom : 1;

    this.localGraph.graph.getModel().beginUpdate();
    try {
      switch (type) {
        case BgImageType.AXES_BG:
          this.localFileToBlob('/assets/axes.svg');
          break;
        case BgImageType.MINI_MAP:
          this.localFileToBlob('/assets/minimap.svg');
          break;
        case BgImageType.CUSTOM_BG:
          let res;
          if (file) {
            res = await this.readerImgForCustomBg(file[0]);
            this.localGraph.uploadFile = res;
          } else {
            res = this.localGraph.uploadFile;
          }
          this.localGraph.graph.setBackgroundImage(await this.createImgForMxGraph(res));
          break;
        case BgImageType.CHESS_BOARD:
        case BgImageType.SIMPLE_GRID:
          this.localGraph.graph.setBackgroundImage(null);
          this.canvasDraw(type);
          break;
        case BgImageType.EMPTY:
          this.clearCanvas(ctx);
          this.localGraph.graph.setBackgroundImage(null);
          break;
      }
      this.createHistoryForCanvasGrid(canvasClone, type,  prevImg);

      this.localGraph.graphBg = type;
      this.localGraph.graph.view.validate();
    } finally {
      this.localGraph.graph.getModel().endUpdate();
    }
  }

  cloneCanvas(oldCanvas, type) {
    const newCanvas = document.createElement('canvas');
    const context = newCanvas.getContext('2d');
    newCanvas.width = oldCanvas.width;
    newCanvas.height = oldCanvas.height;
    (context as any).type = type;
    context.drawImage(oldCanvas, 0, 0);
    return newCanvas;
  }

  createHistoryForCanvasGrid(canvasClone, type, prevImg) {
    const historyForUndo = this.localGraph.graph.getModel().createUndoableEdit(true);
    historyForUndo.add({
      drawGrid: this.changeBgImage,
      undoCanvas: this.undoCanvasState,
      graph: this.localGraph.graph,
      clearCanvas: this.clearCanvas,
      ctx: this,
      type, canvasClone,
      prevImg,
    });
    historyForUndo.notify = function() {
      if (this.undone) {
        this.changes[0].graph.setBackgroundImage(prevImg);
        this.changes[0].clearCanvas.call(this.changes[0].ctx, this.changes[0].ctx.canvas.getContext('2d'));
        this.changes[0].graph.view.validate();
      } else {
        this.changes[0].drawGrid.call(this.changes[0].ctx, this.changes[0].type);
      }
    };
    this.historyService.saveHistory(historyForUndo);
  }

  createImgForCanvas(url) {
    return new Promise(resolve => {
      const image: any = new Image();
      image.src = url;
      image.addEventListener('load', () => {
        resolve(image);
      });
    });
  }

  createImgForMxGraph(src) {
    return new mxImage(src, this.canvas.width, this.canvas.height);
  }

  drawImgOnCanvas(ctx, image) {
    const canvasFactor = this.localGraph.canvasZoom ? this.localGraph.canvasZoom : 1;
    const x = (this.canvas.width / 2) - (image.width / 2) * canvasFactor;
    const y = (this.canvas.height / 2) - (image.height / 2) * canvasFactor;
    ctx.drawImage(image, x, y, image.width * canvasFactor, image.height * canvasFactor);
  }

  readerImgForCustomBg(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file);
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
    });
  }
  localFileToBlob(url: string) {
     this.subs.push(
       this.http.get(url, {responseType: 'blob', observe: 'response'})
         .subscribe(res => {
           this.readerImgForCustomBg(res.body)
             .then(img => this.createImgForMxGraph(img))
             .then(img => {
               this.localGraph.graph.setBackgroundImage(img);
               this.localGraph.graph.view.validate();
             });
         })
     );
  }

  addFigure(type: GraphCellId) {
    this.createFigureService.createFigure({
      type,
      localGraph: this.localGraph,
      graphTranslate: this.graphTranslate,
      isFirstElement: this.isFirstElement
    });
    this.isFirstElement = false;
    this.dragActive = false;
  }

  setMetaDataCellStore() {
    const metaDataCell = this.localGraph.graph.model.cells.metaData;

    if (metaDataCell.data.value !== undefined) {
      metaDataCell.data.value.graphBg = this.localGraph.graphBg;
      metaDataCell.data.value.graphName = this.localGraph.graphName;
      metaDataCell.data.value.graphZoom = this.localGraph.graphZoom;
    } else {
      const metaData = JSON.parse(metaDataCell.data.innerHTML);
      metaData.value.graphBg = this.localGraph.graphBg;
      metaData.value.graphName = this.localGraph.graphName;
      metaData.value.graphZoom = this.localGraph.graphZoom;
      metaDataCell.data.innerHTML = JSON.stringify(metaData);
    }


  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.board.removeEventListener('mouseup', this.onMouseUp, false);
  }
}
