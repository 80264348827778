import {Injectable} from '@angular/core';
import {MxGraphGlobalStoreService} from '../mxgraph-global.store';
import {DataCodecService} from './data-codec.service';
import {LocalStorageService} from '../services/storage-service.service';
import {ActivatedRoute, Router} from "@angular/router";
import {CreateFigureService} from '../services/create-figure.service';


declare var mxUndoManager: any;
declare var mxClient: any;
declare var mxUtils: any;
declare var mxCell: any;
declare var mxConstants: any;
declare var mxCodec: any;
declare var mxObjectCodec: any;
declare var mxGraphHandler: any;
declare var mxKeyHandler: any;
declare var mxPerimeter: any;
declare var mxEditor: any;
declare var mxDefaultToolbar: any;
declare var mxToolbar: any;

@Injectable({
  providedIn: 'root'
})

export class GraphService {
  constructor(private globals: MxGraphGlobalStoreService,
              private dataCodecService: DataCodecService,
              private localStorageService: LocalStorageService,
              private router: Router,
              private createFigureService: CreateFigureService) {}


  convertToXml(graph) {
    const node =  new mxCodec().encode(graph.model);
    return mxUtils.getPrettyXml(node);
  }

  setDefaultStyles(graph) {
    const objStyle = new Object(),
      objTriangleStyle = new Object(),
      objRectangleStyle = new Object(),
      subjStyle = new Object(),
      edgeStyle = graph.getStylesheet().getDefaultEdgeStyle(),
      textStyle = new Object(),
      groupStyle = new Object();

    objStyle[mxConstants.ROUNDED] = true;
    objStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_ELLIPSE;
    objStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    objStyle[mxConstants.STYLE_FONTSIZE] = '18';
    objStyle[mxConstants.STYLE_VERTICAL_ALIGN] = 'bottom';
    objStyle[mxConstants.STYLE_FILLCOLOR] = '#2f2f2f';
    graph.getStylesheet().putCellStyle('objEllipse', objStyle);

    objTriangleStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_IMAGE;
    objTriangleStyle[mxConstants.STYLE_FILLCOLOR] = '#2f2f2f';
    objTriangleStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    objTriangleStyle[mxConstants.STYLE_FONTSIZE] = '18';
    mxConstants.MIN_HOTSPOT_SIZE = 0.001;
    mxConstants.MAX_HOTSPOT_SIZE = 0.001;
    mxConstants.DEFAULT_HOTSPOT = 0.001;

    graph.getStylesheet().putCellStyle('objTriangle', objTriangleStyle);

    objRectangleStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
    objRectangleStyle[mxConstants.STYLE_FILLCOLOR] = '#2f2f2f';
    objRectangleStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    objRectangleStyle[mxConstants.STYLE_FONTSIZE] = '18';

    graph.getStylesheet().putCellStyle('objRectangle', objRectangleStyle);

    subjStyle[mxConstants.STYLE_PERIMETER] = mxPerimeter.RectanglePerimeter;
    subjStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_IMAGE;
    subjStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    subjStyle[mxConstants.STYLE_FONTSIZE] = '18';
    graph.getStylesheet().putCellStyle('man', subjStyle);

    const subjStyle2 = Object.assign({}, subjStyle);
    const subjStyle3 = Object.assign({}, subjStyle);
    graph.getStylesheet().putCellStyle('woman', subjStyle2);
    graph.getStylesheet().putCellStyle('glassesMan', subjStyle3);

    edgeStyle[mxConstants.STYLE_FONTSIZE] = '18';
    edgeStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    edgeStyle[mxConstants.STYLE_EDGE]  = mxConstants.EDGESTYLE_ORTHOGONAL;
    edgeStyle[mxConstants.STYLE_STROKEWIDTH] = 2;
    edgeStyle[mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = 'transparent';
    edgeStyle[mxConstants.STYLE_VERTICAL_ALIGN] = 'top';
    edgeStyle[mxConstants.STYLE_ROUNDED] = true;

    graph.getStylesheet().putCellStyle('edgeS', edgeStyle);

    textStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    textStyle[mxConstants.STYLE_FONTSIZE] = '18';
    textStyle[mxConstants.STYLE_VERTICAL_ALIGN] = 'center';
    textStyle[mxConstants.STYLE_FILLCOLOR] = 'transparent';
    textStyle[mxConstants.STYLE_AUTOSIZE] = true;

    graph.getStylesheet().putCellStyle('customText', textStyle);

    groupStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
    groupStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    groupStyle[mxConstants.STYLE_FONTSIZE] = '19';
    groupStyle[mxConstants.STYLE_FILLCOLOR] = '#39aad647';

    graph.getStylesheet().putCellStyle('group', groupStyle);
  }

  public CustomData = function(value) {
    this.value = value;
  };

  defaultGraphInit(graph) {
    const rubberBand = new mxRubberband(graph);
    const keyHandler = new mxKeyHandler(graph);

    this.dataCodecService.initCodec(this.CustomData);

    this.setDefaultStyles(graph);



    mxGraphHandler.prototype.guidesEnabled = true;
    mxGraphHandler.prototype.outlineConnect = true;
    mxEdgeHandler.prototype.snapToTerminals = true;
    mxEdgeHandler.prototype.outlineConnect = true;

    graph.setPanning(true);
    graph.setConnectable(true);
    graph.setConnectableEdges(false);
    graph.setAllowDanglingEdges(true);
    graph.setDisconnectOnMove(false);
    mxVertexHandler.prototype.rotationEnabled = true;
    graph.centerZoom = false;
    graph.edgeLabelsMovable = false;
    graph.vertexLabelsMovable = false;
    graph.gridSize = 10;
    graph.setVertexLabelsMovable(false);
  }

  clearGraph() {
    const boardNodeList: any = document.getElementById('board') ? document.getElementById('board').children : [];
    if (boardNodeList.length) {
      for (let item of boardNodeList) {
        if (item.nodeName === 'svg' || item.className === 'domNode') {
          item.remove();
        }
      }
    }
  }

  getNewGraph(container: HTMLElement) {
    return new mxGraph(container);
  }


  createGraph() {
    this.globals.setGraphStatus(false);
    this.clearGraph();

    const graph = this.getNewGraph(document.getElementById('board'));

    this.defaultGraphInit(graph);

    const directoryName = prompt('Введите наименование проекта');
    const projectName = prompt('Введите наименование доски');

    const graphObj = Object.assign(
      {
        graph: this.convertToXml(graph),
        graphId: 0,
        directoryName,
        graphName: projectName,
        graphBg: '',
        graphZoom: 1,
        canvasZm: 1,
        fileUpload: null,
      }, null);

    this.globals.createBard(graphObj).subscribe(item => {
      this.localStorageService.saveGraphList({id: item.data.id, name: item.data.attributes.board_data.graphName});
      this.router.navigateByUrl(`board/${item.data.id}`);
    });
  }

  saveGraph(graph) {
    this.globals.editBoards({
      graph: this.convertToXml(graph.graph),
      graphId: graph.graphId,
      directoryName: graph.directoryName,
      graphName: graph.graphName,
      graphBg: graph.graphBg,
      graphZoom: graph.graphZoom,
      canvasZoom: graph.canvasZoom,
      fileUpload: graph.uploadFile ? graph.uploadFile : null
    }, graph.graphId).subscribe();
  }
}
