import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

export class BoardInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const cloned = req.clone({
      setHeaders: {
        'Accept': 'application/vnd.api+json',
        'Content-type': 'application/vnd.api+json',
      },
    });
    return next.handle(cloned);
  }
}
