export enum ZoomValue {
  ZOOM_PLUS = 1.1,
  ZOOM_MINUS = 0.9,
  ZOOM_NORMALIZE = 1,
  CANVAS_ZOOM_STEP = 0.05,
}

export enum BgImageType {
  AXES_BG = 'axesBg',
  MINI_MAP = 'mnimap',
  CUSTOM_BG = 'customBg',
  CHESS_BOARD = 'chessBoard',
  SIMPLE_GRID = 'simpleGrid',
  EMPTY = 'empty',
}
