import {Injectable} from '@angular/core';
import {
  ArrowPoint,
  GraphCellGeometry,
  GraphCellId,
  GraphCellStyle,
  CellDefaultText,
  defaultStyleForArrows
} from '../interfaces/grahp-cell.interfaces';

declare var mxGeometry: any;
declare var mxCell: any;
declare var mxEventObject: any;

@Injectable({
  providedIn: 'root'
})

export class CreateFigureService {
  constructor() {}

  createFigure({type, localGraph, graphTranslate, isFirstElement, isDragCreate, mouseСoordinates}: { type: GraphCellId, localGraph: any, graphTranslate: any, isFirstElement: boolean, isDragCreate?: boolean, mouseСoordinates?: {x: number, y: number}}) {
    const coordinatesX = mouseСoordinates ? mouseСoordinates.x : this.calculateXCoordinates({type, localGraph, graphTranslate, isFirstElement});
    const coordinatesY = mouseСoordinates ? mouseСoordinates.y : this.calculateYCoordinates({type, localGraph, graphTranslate});
    let arrowCell;

    localGraph.graph.getModel().beginUpdate();
    try {
      switch (type) {
        case GraphCellId.TRIANGLE:
          const triangle = localGraph.graph.insertVertex(localGraph.graph.getDefaultParent(), null, CellDefaultText.OBJECT_DEFAULT_TEXT, coordinatesX, coordinatesY, this.calculateVertexSize(localGraph), this.calculateVertexSize(localGraph), GraphCellStyle.TRIANGLE_STYLE);
          triangle.typeOfFigure = GraphCellId.TRIANGLE;
          break;
        case GraphCellId.ELLIPSE:
          const ellipse = localGraph.graph.insertVertex(localGraph.graph.getDefaultParent(), null, CellDefaultText.OBJECT_DEFAULT_TEXT, coordinatesX, coordinatesY, this.calculateVertexSize(localGraph), this.calculateVertexSize(localGraph), GraphCellStyle.ELLIPSE_STYLE);
          ellipse.typeOfFigure = GraphCellId.ELLIPSE;
          break;
        case GraphCellId.SQUARE:
          const square = localGraph.graph.insertVertex(localGraph.graph.getDefaultParent(), null, CellDefaultText.OBJECT_DEFAULT_TEXT, coordinatesX, coordinatesY, this.calculateVertexSize(localGraph), this.calculateVertexSize(localGraph), GraphCellStyle.SQUARE_STYLE);
          square.typeOfFigure = GraphCellId.SQUARE;
          break;
        case GraphCellId.MAN:
          const man = localGraph.graph.insertVertex(localGraph.graph.getDefaultParent(), null, CellDefaultText.SUBJECT_DEFAULT_TEXT, coordinatesX, coordinatesY, this.calculateVertexSize(localGraph), this.calculateVertexSize(localGraph), GraphCellStyle.MAN_STYLE);
          man.typeOfFigure = GraphCellId.MAN;
          break;
        case GraphCellId.WOMAN:
          const woman = localGraph.graph.insertVertex(localGraph.graph.getDefaultParent(), null, CellDefaultText.SUBJECT_DEFAULT_TEXT, coordinatesX, coordinatesY, this.calculateVertexSize(localGraph), this.calculateVertexSize(localGraph), GraphCellStyle.WOMAN_STYLE);
          woman.typeOfFigure = GraphCellId.WOMAN;
          break;
        case GraphCellId.GLASSES_MAN:
          const glassesMan = localGraph.graph.insertVertex(localGraph.graph.getDefaultParent(), null, CellDefaultText.SUBJECT_DEFAULT_TEXT, coordinatesX, coordinatesY, this.calculateVertexSize(localGraph), this.calculateVertexSize(localGraph), GraphCellStyle.GLASSES_MAN_STYLE);
          glassesMan.typeOfFigure = GraphCellId.GLASSES_MAN;
          break;
        case GraphCellId.ROTATE_TEXT:
          const rotateText = localGraph.graph.insertVertex(localGraph.graph.getDefaultParent(), null, CellDefaultText.DEFAULT_TEXT, coordinatesX, coordinatesY, GraphCellGeometry.TEXT_DEFAULT_WIDTH, GraphCellGeometry.TEXT_DEFAULT_HEIGHT, GraphCellStyle.ROTATE_TEXT_STYLE);
          rotateText.typeOfFigure = GraphCellId.ROTATE_TEXT;
          break;
        case GraphCellId.TEXT:
          const text = localGraph.graph.insertVertex(localGraph.graph.getDefaultParent(), null, CellDefaultText.DEFAULT_TEXT, coordinatesX, coordinatesY, GraphCellGeometry.TEXT_DEFAULT_WIDTH, GraphCellGeometry.TEXT_DEFAULT_HEIGHT, GraphCellStyle.TEXT_STYLE);
          text.typeOfFigure = GraphCellId.TEXT;
          break;
        case GraphCellId.DASHED:
          arrowCell = new mxCell(GraphCellId.DASHED, this.createDefaultGeometry(), this.generateStyle(defaultStyleForArrows.dashedArrow));
          arrowCell.geometry.setTerminalPoint(new mxPoint((coordinatesX + ArrowPoint.SOURCE_X) - 200, coordinatesY), true);
          arrowCell.geometry.setTerminalPoint(new mxPoint((coordinatesX + ArrowPoint.TARGET_X) - 200, coordinatesY), false);
          arrowCell.typeOfFigure = GraphCellId.DASHED;
          arrowCell.styleObject = defaultStyleForArrows.dashedArrow;
          break;
        case GraphCellId.DOTTED:
          arrowCell = new mxCell(GraphCellId.DOTTED, this.createDefaultGeometry(), this.generateStyle(defaultStyleForArrows.dottedArrow));
          arrowCell.geometry.setTerminalPoint(new mxPoint((coordinatesX + ArrowPoint.SOURCE_X) - 200, coordinatesY), true);
          arrowCell.geometry.setTerminalPoint(new mxPoint((coordinatesX + ArrowPoint.TARGET_X) - 200, coordinatesY), false);
          arrowCell.typeOfFigure = GraphCellId.DOTTED;
          arrowCell.styleObject = defaultStyleForArrows.dottedArrow;
          break;
        case GraphCellId.DEFAULT_ARROW:
          arrowCell = new mxCell(GraphCellId.DEFAULT_ARROW, this.createDefaultGeometry(), this.generateStyle(defaultStyleForArrows.defaultArrow));
          arrowCell.geometry.setTerminalPoint(new mxPoint((coordinatesX + ArrowPoint.SOURCE_X) - 200, coordinatesY), true);
          arrowCell.geometry.setTerminalPoint(new mxPoint((coordinatesX + ArrowPoint.TARGET_X) - 200, coordinatesY), false);
          arrowCell.typeOfFigure = GraphCellId.DEFAULT_ARROW;
          arrowCell.styleObject = defaultStyleForArrows.defaultArrow;
          break;
        case GraphCellId.GROUP_OBJECT:
          const groupObject = localGraph.graph.insertVertex(localGraph.graph.getDefaultParent(), null, GraphCellId.GROUP_OBJECT, coordinatesX, coordinatesY, GraphCellGeometry.GROUP_OBJECT_DEFAULT_WIDTH, GraphCellGeometry.GROUP_OBJECT_DEFAULT_HEIGHT, GraphCellStyle.GROUP_OBJECT_STYLE);
          groupObject.typeOfFigure = GraphCellId.GROUP_OBJECT;
          if (localGraph.graph.getSelectionCells().length) {
            const selectedCells = localGraph.graph.getSelectionCells();
            localGraph.graph.groupCells(groupObject, GraphCellGeometry.GROUP_BORDER_SIZE, selectedCells);
            localGraph.graph.setSelectionCell(groupObject);
          }
          break;
      }
    } finally {
      if (arrowCell) {
        arrowCell.geometry.relative = true;
        arrowCell.edge = true;
        arrowCell.connectable = true;
        arrowCell = localGraph.graph.addCell(arrowCell);
        localGraph.graph.fireEvent(new mxEventObject('cellsInserted', 'cells', [arrowCell]));
      }
      localGraph.graph.getModel().endUpdate();
    }
  }


  calculateXCoordinates({type, localGraph, graphTranslate, isFirstElement}: { type: GraphCellId, localGraph: any, graphTranslate: any, isFirstElement: boolean }): number {
    switch (type) {
      case GraphCellId.DASHED:
      case GraphCellId.DEFAULT_ARROW:
      case GraphCellId.DOTTED:
        return -(graphTranslate.x - GraphCellGeometry.DEFAULT_ARROW_TRANSLATE_X) / localGraph.graphZoom;
        break;
      case GraphCellId.ELLIPSE:
      case GraphCellId.GLASSES_MAN:
      case GraphCellId.MAN:
      case GraphCellId.ROTATE_TEXT:
      case GraphCellId.SQUARE:
      case GraphCellId.TEXT:
      case GraphCellId.TRIANGLE:
      case GraphCellId.WOMAN:
      case GraphCellId.GROUP_OBJECT:
        if (isFirstElement) {
          graphTranslate.x += -500;
        }
        return -(graphTranslate.x -= GraphCellGeometry.FIGURE_DEFAULT_TRANSLATE_X) / localGraph.graphZoom;
        break;
    }
  }

  calculateYCoordinates({type, localGraph, graphTranslate}: { type: GraphCellId, localGraph: any, graphTranslate: any }): number {
    switch (type) {
      case GraphCellId.DASHED:
      case GraphCellId.DEFAULT_ARROW:
      case GraphCellId.DOTTED:
        return -(graphTranslate.y -= GraphCellGeometry.DEFAULT_ARROW_TRANSLATE_Y) / localGraph.graphZoom;
        break;
      case GraphCellId.ELLIPSE:
      case GraphCellId.GLASSES_MAN:
      case GraphCellId.MAN:
      case GraphCellId.ROTATE_TEXT:
      case GraphCellId.SQUARE:
      case GraphCellId.TEXT:
      case GraphCellId.TRIANGLE:
      case GraphCellId.WOMAN:
      case GraphCellId.GROUP_OBJECT:
        return -graphTranslate.y / localGraph.graphZoom;
        break;

    }
  }

  calculateVertexSize(localGraph) {
    const scale = localGraph.graph.view.getScale();
    let size = GraphCellGeometry.FIGURE_DEFAULT_WIDTH;
    if (scale !== 1) {
      size = size / scale;
    }
    return size;
  }

  generateStyle(styleObject) {
    const styleArr = [];
    for (const key in styleObject) {
      if (styleObject[key] instanceof Object) {
        styleArr.push(Object.values(styleObject[key]).join(';'));
      } else {
        styleArr.push(styleObject[key]);
      }
    }
    return styleArr.join(';');
  }

  createDefaultGeometry() {
    return new mxGeometry(0, 0, 0, 0);
  }

  createDragPointer(localGraph) {
    const drag = localGraph.graph.insertVertex
    (
      localGraph.graph.getDefaultParent(),
      'drag',
      null,
      -1000,
      -1000,
      50 / localGraph.graphZoom,
      50 / localGraph.graphZoom,
      'fill=none;stroke=black;stroke-dasharray=3 3'
    );
    drag.setConnectable(false);
    drag.setVisible(false);
    localGraph.graph.getModel().add(localGraph.graph.getDefaultParent(), drag, 0);

    return drag;
  }
}
