import { OnDestroy } from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { StorageMap } from '@ngx-pwa/local-storage';
import { MxGraphGlobalStoreService, IProjectState } from '../mxgraph-global.store';
import { Observable, Subscription } from 'rxjs';

const STORAGE_KEY = 'local_boardList';

declare var mxUtils: any;
declare var mxCodec: any;

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService implements  OnDestroy {

  anotherBoardList = [];
  subs: Subscription[] = [];
  graphs: any;

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, @Inject(LOCAL_STORAGE) private mystorage: StorageMap, private store: MxGraphGlobalStoreService) {
  }

  set localGraph(graph) {
    this.store.saveGraphItem(graph);
  }

  get localGraph(): any {
    return this.store.state.graphs[0];
  }

  public storeInLocalStorage(graph: any, graphId: number, directoryName: string, graphName: string, graphBg: string, graphZoom: number, canvasZoom: number, uploadFile?: any): void {
    const currentBoardList = this.storage.get(STORAGE_KEY) || [];
    const currGraph = currentBoardList.find((item) => item.graphId === graphId);
    if (currGraph) {
      currentBoardList[currGraph.graphId] = {graph, graphId, directoryName, graphName, graphBg, graphZoom, canvasZoom, uploadFile};
    } else {
      currentBoardList.push({graph, graphId, directoryName, graphName, graphBg, graphZoom, uploadFile});
    }

    this.storage.set(STORAGE_KEY, currentBoardList);
    console.log('storeInLocalStorage');
  }

  public checkIfStorageExist(): boolean {
    const currentBoardList = this.storage.get(STORAGE_KEY);
    return currentBoardList !== undefined;
  }

  public checkIfStorageHasMoreThanInitial(): boolean {
    const currentBoardList = this.storage.get(STORAGE_KEY);
    if (currentBoardList !== undefined) {
      return currentBoardList.length > 1;
    } else {
      return false;
    }
  }

  public saveGraphList(item: {id: string, name: string}): void {
    let boardList = this.storage.get('bardList') || [];
    if (boardList.length) {
      const index = boardList.findIndex( listItem => listItem.id === item.id);
      if (index === -1) {
        boardList.push(item);
      } else {
        boardList[index] = item;
      }
    } else {
      boardList.push(item);
    }
    this.storage.set('bardList', boardList);
  }

  getBoardList(): any[] {
    return this.storage.get('bardList');
  }

  public getInitialBoardFromStorage(): any {
    const currentBoardList = this.storage.get(STORAGE_KEY);
    if (currentBoardList !== undefined) {
      return currentBoardList[0];
    } else {
      return false;
    }
  }

  public getBoardsFromStorage(): any {
    const currentBoardList = this.storage.get(STORAGE_KEY);
    return currentBoardList.slice(1);
  }

  decodeModel() {
    let encoder = new mxCodec();
    let node = encoder.encode(this.localGraph.graph.model);
    return mxUtils.getPrettyXml(node);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
