import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  emitter = new EventEmitter();


  constructor() { }

  invokeComponentAction(type) {
    this.emitter.emit(type);
  }
}
