import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MxGraphGlobalStoreService, IProjectState } from '../../mxgraph-global.store';
import { Subscription } from 'rxjs';
import { UploadResourceService } from '../../resources/upload.resource';
import { EventEmitterService } from '../../services/event-emmiter.service';
import { DataCodecService } from '../../services/data-codec.service';
import {LocalStorageService} from '../../services/storage-service.service';
import {EventEmitterType} from '../../interfaces/event-emitter.interface';
import {ActivatedRoute, Router} from "@angular/router";
import {GraphService} from '../../services/graph.service';

import html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';

declare var mxUtils: any;
declare var mxCodec: any;
declare var mxLayoutManager: any;
declare var mxCell: any;
declare var mxGraphView: any;

@Component({
  selector: 'app-header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  subs: Subscription[] = [];
  graphs;
  parent;
  selected;
  counter = 1;
  projectName;
  directoryName: string;
  menuIsOpened: boolean = false;
  changedNameValue: string = '';
  boardList: any;

  @ViewChild('matSelect', {static: false}) matSelect: ElementRef;
  @ViewChild('fileUploadXml', {static: false}) fileInput: ElementRef;

  constructor(private globals: MxGraphGlobalStoreService,
              private uploadResource: UploadResourceService,
              private eventEmitterService: EventEmitterService,
              private dataCodecService: DataCodecService,
              private localStorageService: LocalStorageService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private graphService: GraphService) { }

  ngOnInit() {
    this.uploadResource.uploaderXML.onCompleteItem = (item: any, response: any, status: any, headers: any) => {

      const layout = new mxLayoutManager(this.localGraph.graph);

      const reader = new FileReader();
      reader.readAsText(item.file.rawFile);

      reader.onloadend = () =>  {
        this.localGraph.graph.getModel().beginUpdate();
        try {
          // var zoomFactor = this.readLocalXML(this.localGraph, reader.result);
          this.readLocalXML(this.localGraph, reader.result);
        } finally {
          layout.executeLayout(this.localGraph.graph.getDefaultParent());
          this.localGraph.graph.getModel().endUpdate();
        }
      };
      this.fileInput.nativeElement.value = '';
    };

    this.subs.push(
      this.globals.state$.subscribe((state: IProjectState) => {
        this.graphs = state.graphs;
        this.parent = state.parent;
        this.selected = state.selectedValue;
        this.changedNameValue = state.selectedValue;
        this.projectName = state.projectName;
      })
    );

    if (this.localStorageService.checkIfStorageHasMoreThanInitial()) {
      const boardNodeList: any = document.getElementById('board').children;
      const otherBoards = this.localStorageService.getBoardsFromStorage();
      this.counter = otherBoards.length;
      this.counter++;

      for (let i = 0; i < otherBoards.length; i++) {
        let board = otherBoards[i];
        let str = board.graph.slice(15, board.graph.length - 16);
        board.graph = str;
        let doc = mxUtils.parseXml(board.graph);
        let codec = new mxCodec(doc);
        let temp = this.initEmptyGraph();
        let elt = doc.documentElement.firstChild;
        let cells = [];
        let metaData;
        let style;

        while (elt !== null) {
          if (elt.id === 'metaData') {
            const metaDataCell = codec.decode(elt);
            metaData = JSON.parse(metaDataCell.data.innerHTML);
            elt.data = metaData.value;
            style = metaDataCell.style;
          }
          cells.push(codec.decode(elt));

          elt = elt.nextSibling;
        }

        temp.addCells(cells, null, null, null, null);
        temp.model.cells.metaData.data = metaData;
        temp.model.cells.metaData.style = style;
        board.graph = temp;
        this.graphs.push(board);

        for (let item of boardNodeList) {
          if (item.nodeName === 'svg' && item.nextSibling === null) {
            item.remove();
          }
        }
      }
    }

    this.boardList = this.localStorageService.getBoardList() || [];
  }

  ngAfterViewInit(): void {}

  get localGraph(): any {
    return this.globals.state.graphs.find((graph) => graph.graphName === this.selected);
  }

  set localGraph(graph) {
    this.globals.saveGraphItem(graph);
  }

  get activeOption() {
    return this.globals.state.selectedValue;
  }

  set activeOption(selected) {
    this.globals.setOptionValue(selected);
    this.changedNameValue = selected;
  }

  get directoryNameInStore() {
    return this.globals.state.projectName;
  }

  set directoryNameInStore(name) {
    this.globals.saveProjectName(name);
  }

  changeMenuState() {
    this.menuIsOpened = !this.menuIsOpened;
  }

  CustomData = function(value) {
    this.value = value;
  };

  initEmptyGraph() {
    const board = document.getElementById('board') as HTMLElement;
    const graph = new mxGraph(board);

    const cell = new mxCell('metaData', null, null );
    cell.id = 'metaData';
    graph.addCell(cell, null, null, null, null);
    return graph;
  }

  initNewBoard() {
    let graphName = prompt('Для создания новой доски, введите ее наименование:');

    if (graphName) {
      this.boardList.forEach(item => {
        if (graphName === item.name) {
          alert('Existing graph name. Choose another.');
          graphName = prompt('Для создания новой доски, введите ее наименование:');
        }
      });

      this.localGraph.graph.getModel().beginUpdate();
      try {
        this.localGraph.graph.destroy();
        const graphObj = Object.assign({graph: this.graphService.convertToXml(this.initEmptyGraph()), graphId: null,  graphName, graphBg: '', graphZoom: 1, canvasZoom: 1}, null);
        this.globals.createBard(graphObj).subscribe(item => {
          this.router.navigateByUrl(this.router.url.replace(this.activatedRoute.snapshot.paramMap.get('id'), item.data.id))
            .then(() => {
              this.globals.setGraphStatus(false);
              this.selected = graphName;
            });
        });
      } finally {
        this.localGraph.graph.getModel().endUpdate();
      }
    } else {
      alert('Board has to have a name.');
    }
  }

  loadBoard(graph) {
    if (graph.id === this.activatedRoute.snapshot.paramMap.get('id')) return;
    this.localGraph.graph.setSelectionCells(null);
    this.localGraph.graph.getModel().beginUpdate();
    try {
      this.localGraph.graph.destroy();
      this.router.navigateByUrl(this.router.url.replace(this.activatedRoute.snapshot.paramMap.get('id'), graph.id))
        .then(() => {
          this.globals.setGraphStatus(false);
          this.selected = graph.name;
          });
    } finally {
      this.localGraph.graph.getModel().endUpdate();
    }
  }

  downloadXMLFile() {
    const file = new Blob([this.ViewXML()], {type: 'application/xml'});
    let fileName = prompt(  'Введите имя файла, для сохранения доски на вашем рабочем столе:');
    if (fileName) {
      saveAs(file, fileName + '.xml');
    } else {
      alert('Xml has to have a name.');
    }
  }

  readLocalXML = (graph, filename) => {
    const doc = mxUtils.parseXml(filename);
    const dec = new mxCodec(doc);
    const temp = this.localGraph.graph;
    dec.decode(doc.documentElement, this.localGraph.graph.model);

    this.localGraph.graph.model.mergeChildren(temp.getDefaultParent(), this.localGraph.graph.getDefaultParent());

    const metaDataCell = this.localGraph.graph.model.cells.metaData;
    let metaData;

    if (metaDataCell.data.value !== undefined) {
      metaData = metaDataCell;
    } else {
       metaData = JSON.parse(metaDataCell.data.innerHTML);
    }
    this.localGraph.graphZoom = metaData.value.graphZoom;
    this.localGraph.graphBg = metaData.value.graphBg;
    if (this.localGraph.graphZoom) {
      this.localGraph.graph.zoom(this.localGraph.graphZoom, true);
    } else {
      this.localGraph.graph.zoomActual();
    }
    this.localGraph.graph.view.validate();
  }

  onFileSelected() {
    this.uploadResource.uploaderXML.uploadAll();
  }

  toggleSelectList() {
    (this.matSelect as any).toggle();
  }

  changeBoardName() {
    this.localGraph.graphName = this.changedNameValue;
    this.activeOption = this.changedNameValue;
    this.graphService.saveGraph(this.localGraph);
  }

  changeDirectoryName(value) {
    this.localGraph.directoryName = value;
    this.directoryNameInStore = value;
    this.graphService.saveGraph(this.localGraph);
  }

  clearModel() {
    let canvas: any = document.querySelector('canvas') as HTMLElement;

    this.localGraph.graph.removeCells(this.localGraph.graph.getChildCells(this.localGraph.graph.getDefaultParent(), true, true), true);

    this.localGraph.graph.setBackgroundImage(null);

    this.localGraph.graph.getModel().getCell('metaData').style = '';

    let context = canvas.getContext('2d');

    context.clearRect(0, 0, canvas.width, canvas.height);

    const metaDataCell = this.localGraph.graph.model.cells.metaData;

    for (const item in this.graphs)  {
      if (this.graphs[item].graphName === this.selected) {
        this.graphs[item].graphBg = '';
        this.graphs[item].graphZoom = 1;
        if ( metaDataCell.data.value !== undefined) {
          metaDataCell.data.value.graphBg = '';
          metaDataCell.data.value.graphZoom = 1;
        } else {
          const metaData = JSON.parse(metaDataCell.data.innerHTML);
          metaData.value.graphBg = '';
          metaData.value.graphZoom = 1;
          metaDataCell.data.innerHTML = JSON.stringify(metaData);
        }
      }
    }



    this.localGraph.graph.zoomActual();

    this.eventEmitterService.invokeComponentAction(EventEmitterType.GRIDS);
    this.eventEmitterService.invokeComponentAction(EventEmitterType.POSITION);
    this.eventEmitterService.invokeComponentAction(EventEmitterType.CLEAR);

    this.localGraph.graph.view.validate();
  }

  ViewXML() {
    let encoder = new mxCodec();
    let node = encoder.encode(this.localGraph.graph.model);
    return mxUtils.getPrettyXml(node);
  }

  shareLink() {
    navigator.clipboard.writeText((window as any).location).then(() => alert('Ссылка скопирована в буфер обмена'));
  }

  saveBoard() {
    this.graphService.saveGraph(this.localGraph)
  }

  domToImage(type: string) {
    const board = document.querySelector('#board') as HTMLElement;
    const dt = new Date();
    let dateFormated = dt.getDate() + '/' + (dt.getMonth() + 1) + '/' + dt.getFullYear();

    if (type === 'png') {
      html2canvas(board).then(canvas => {
        window.saveAs(canvas.toDataURL(), `board_screenshot-${dateFormated}.png`);
      });

    } else {
      html2canvas(board).then(canvas => {
        window.saveAs(canvas.toDataURL('image/jpeg'), `board_screenshot-${dateFormated}.jpg`);
      });
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  invokeUndoRedo(type) {
    this.eventEmitterService.invokeComponentAction(type);
  }

}
