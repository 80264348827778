import { BrowserModule } from '@angular/platform-browser';
import {NgModule, Provider} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './modules/material-module/material-module.module';
import {StorageServiceModule } from 'ngx-webstorage-service';
import { StorageModule } from '@ngx-pwa/local-storage';

import { FileSelectDirective } from 'ng2-file-upload';

import { EventEmitterService } from './services/event-emmiter.service';
import { SideNavsService } from './services/side-navs.service';
import { LocalStorageService } from './services/storage-service.service';

import { MxGraphGlobalStoreService } from './mxgraph-global.store';
import {RouterModule} from "@angular/router";
import { AppComponent } from './app.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { HeaderComponent } from './components/header-component/header-component.component';
import { AsideNavComponentComponent } from './components/aside-nav-component/aside-nav.component';
import { BoardComponent } from './components/board/board.component';
import { CharacteristicsComponent } from './components/characteristics-component/characteristics-component.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { jqxColorPickerComponent } from 'jqwidgets-ng/jqxcolorpicker';
import {BoardInterceptor} from './services/board.interceptor';
import {InlineSVGModule} from "ng-inline-svg";
import {MatInputModule} from "@angular/material/input";



const interceptor: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: BoardInterceptor,
  multi: true,
};

const routes = [
  { path: 'board/:id',               component: MainLayoutComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    AsideNavComponentComponent,
    HeaderComponent,
    BoardComponent,
    FileSelectDirective,
    CharacteristicsComponent,
    jqxColorPickerComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    RouterModule,
    RouterModule,
    RouterModule.forChild(routes),
    MatExpansionModule,
    StorageServiceModule,
    StorageModule.forRoot({IDBNoWrap: true}),
    InlineSVGModule,
    MatInputModule
  ],
  providers: [ MxGraphGlobalStoreService,
    EventEmitterService,
    SideNavsService,
    LocalStorageService,
    interceptor,
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
