import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { FileUploader, FileSelectDirective } from 'ng2-file-upload/ng2-file-upload';

const URL = 'https://fake-api/response';

@Injectable({
  providedIn: 'root'
})
export class UploadResourceService  {
  public uploaderXML: FileUploader = new FileUploader({ url: URL, itemAlias: 'xml', autoUpload: true, allowedMimeType: ['text/xml'], maxFileSize: 2048 * 2048 * 1});
  public uploaderImg: FileUploader = new FileUploader({ url: URL, itemAlias: 'image' , removeAfterUpload: true, autoUpload: true,
  allowedMimeType: ['image/gif', 'image/png', 'image/jpeg', 'image/jpeg', 'image/webp', 'image/svg+xml'], maxFileSize: 2048 * 2048 * 1});

  constructor(private http: HttpClient) {
    this.uploaderXML.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploaderImg.onAfterAddingFile = (file) => { file.withCredentials = false; };

    this.uploaderXML.onWhenAddingFileFailed = (item: any, filter: any, options: any) =>  {
      switch (filter.name) {
        case 'fileSize':
          alert('Inappropriate file size. Maximum file size is 2 MB.');
          break;
        case 'mimeType':
          alert('Inappropriate file extension. Expecting XML!');
          break;
        default:
          alert('Oops, something went wrong.');
      }
    };

    this.uploaderImg.onWhenAddingFileFailed = (item: any, filter: any, options: any) =>  {
      switch (filter.name) {
        case 'fileSize':
          alert('Inappropriate file size. Maximum file size is 2 MB.');
          break;
        case 'mimeType':
          alert('Inappropriate file extension. Expecting png/svg/jpeg/jpg/gif or webp!');
          break;
        default:
          alert('Oops, something went wrong.');
      }
    };
  }
}
