import {Injectable} from '@angular/core';
import {BgImageType, ZoomValue} from '../interfaces/zoom-control.interface';
import {MxGraphGlobalStoreService} from '../mxgraph-global.store';
import {EventEmitterService} from '../services/event-emmiter.service';
import {EventEmitterType} from '../interfaces/event-emitter.interface';

@Injectable({
  providedIn: 'root'
})

export class ZoomControlService {
  constructor(private globals: MxGraphGlobalStoreService,
              private eventEmitterService: EventEmitterService) {}


  canvasFactor: number;
  canvasCurrentImg: HTMLImageElement;

  onZooming(factor, graph) {
    switch (factor) {
      case ZoomValue.ZOOM_PLUS:
      case ZoomValue.ZOOM_MINUS:
        graph.zoom(factor, true);
        break;
      case ZoomValue.ZOOM_NORMALIZE:
        graph.zoomActual();
        break;
    }
    this.eventEmitterService.invokeComponentAction(EventEmitterType.ZOOM);
  }

  saveZoomValue(graph) {
    const metaDataCell = graph.model.cells.metaData;
    if (metaDataCell.data.value) {
        metaDataCell.data.value.graphZoom = graph.getView().getScale();
    } else {
      const metaData = JSON.parse(metaDataCell.data.innerHTML);
      metaData.value.graphZoom = graph.getView().getScale();
      metaDataCell.data.innerHTML = JSON.stringify(metaData);
    }
  }

  scaleCanvasBg(graph, factor, canvas) {
    switch (graph.graphBg) {
      case BgImageType.SIMPLE_GRID:
      case BgImageType.CHESS_BOARD:
        this.eventEmitterService.invokeComponentAction(EventEmitterType.REDRAW_GRID);
        break;
    }
  }

  onCanvasImgScale(graph, factor, canvas) {
    this.canvasFactor = graph.canvasZoom || factor;
    this.transformCanvasFactor(factor);

    const x = (canvas.width / 2) - (this.canvasCurrentImg.width / 2) * this.canvasFactor;
    const y = (canvas.height / 2) - (this.canvasCurrentImg.height / 2) * this.canvasFactor;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(this.canvasCurrentImg, x, y, this.canvasCurrentImg.width * this.canvasFactor, this.canvasCurrentImg.height * this.canvasFactor);
    graph.canvasZoom = this.canvasFactor;
  }

  getCurrentZoomValue(graph) {
    return graph.getView().getScale();
  }

  saveCurrentImg(img) {
    this.canvasCurrentImg = img;
  }

  transformCanvasFactor(factor) {
    switch (factor) {
      case ZoomValue.ZOOM_PLUS:
        this.canvasFactor = this.canvasFactor + ZoomValue.CANVAS_ZOOM_STEP;
        break;
      case ZoomValue.ZOOM_MINUS:
        if (Math.sign(this.canvasFactor) !== -1) {
          this.canvasFactor = this.canvasFactor - ZoomValue.CANVAS_ZOOM_STEP;
        }
        break;
      case ZoomValue.ZOOM_NORMALIZE:
        this.canvasFactor = ZoomValue.ZOOM_NORMALIZE;
    }
  }
}
