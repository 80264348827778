import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { map, filter } from 'rxjs/operators';


export interface IStoreAction {
  type: string;
  [id: string]: any;
}

export interface IStore<T> {
  action$: Observable<IStoreAction>;
  state$: Observable<T>;

  readonly state: T;
}

/**
 * Default store class. Use this class for create new store.
 */
export class StoreService<T> implements IStore<T>, OnDestroy {

  protected _subs: Subscription[] = [];
  protected _stateSubject: BehaviorSubject<T>;
  protected _actionSubject = new Subject<IStoreAction>();

  public action$ = this._actionSubject.asObservable();
  public state$: Observable<T>;

  public get state(): T { return this._stateSubject.value; }

  constructor(defaultState: T) {
    this._stateSubject = new BehaviorSubject<T>(defaultState);
    this.state$ = this._stateSubject
      .asObservable()
      .pipe(filter(Boolean));
  }

  select<I>(selector: (state: T) => I) {
    return this.state$.pipe(map(state => selector(state)),
      filter(value => Boolean(value)));
  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe());
  }

}
