import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { MxGraphGlobalStoreService } from './mxgraph-global.store';
import { DataCodecService } from './services/data-codec.service';
import { LocalStorageService } from './services/storage-service.service';
import { EventEmitterService } from './services/event-emmiter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GraphService} from './services/graph.service';

declare var mxClient: any;
declare var mxUtils: any;
declare var mxCell: any;
declare var mxConstants: any;
declare var mxCodec: any;
declare var mxObjectCodec: any;
declare var mxGraphHandler: any;
declare var mxKeyHandler: any;
declare var mxPerimeter: any;
declare var mxEditor: any;
declare var mxDefaultToolbar: any;
declare var mxToolbar: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  directoryName;
  selected;
  canShow = false;

  @ViewChild('board', {static: false}) board: ElementRef;

  constructor(private globals: MxGraphGlobalStoreService,
              private dataCodecService: DataCodecService,
              private localStorageService: LocalStorageService,
              private eventEmitterService: EventEmitterService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private graphService: GraphService) { }

  get localGraph(): any {
    return this.globals.state.graphs.find((graph) => graph.graphName === this.selected);
  }

  get activeOption() {
    return this.globals.state.selectedValue;
  }

  set activeOption(selected) {
    this.globals.setOptionValue(selected);
  }

  ngOnInit() {
    if (!mxClient.isBrowserSupported()) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
        if (window.location.pathname.split('/board/')[1]) {
        this.initProject(true, window.location.pathname.split('/board/')[1]);
      } else {
        this.initProject(false);
      }
    }
  }

  ngAfterViewInit(): void {}


  initProject(isNewBoard: boolean, id?: string) {

    if (isNewBoard) {
      this.router.navigateByUrl(`board/${id}`);
    } else  {
      if (this.localStorageService.getBoardList()) {
        const boardList = this.localStorageService.getBoardList();
        const id = boardList[boardList.length - 1].id;
        this.router.navigateByUrl(`board/${id}`);
      } else {
        this.graphService.createGraph();
      }
    }
  }
}
