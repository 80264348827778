import { Injectable, ViewChild, ElementRef} from '@angular/core';
import {IStore, StoreService} from './stores/store';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";

export interface IProjectState {
  graphs: any[];
  parent: any;
  projectName: string;
  selectedValue: string;
  canvasEL: HTMLElement | string;
  currentGraphId: string;
  graphReady: boolean;
}

@Injectable()
export class MxGraphGlobalStoreService extends StoreService<IProjectState> implements IStore<IProjectState> {

  static defaultState: IProjectState = {
    graphs: [],
    parent: {},
    projectName: '',
    selectedValue: '',
    canvasEL: '',
    currentGraphId: '',
    graphReady: false,
  };

  @ViewChild('board', {static: false}) board: ElementRef;

  constructor(private http: HttpClient) {
    super(MxGraphGlobalStoreService.defaultState);
  }

  saveGraphItem(graph: any) {
    const currGraphs: any[] = [...this.state.graphs];
    const index = currGraphs.findIndex(item => item.graphId === graph.graphId);
    if (index === -1) {
      currGraphs.push(graph);
    } else {
      currGraphs[index] = graph;
    }
    this._stateSubject.next({
      ...this.state,
      graphs: currGraphs,
    });
    console.log('saveGraph store change');
  }

  saveProjectName(projectName?: string) {
    this._stateSubject.next({
      ...this.state,
      projectName
    });
    console.log('savePrjName store change');
  }

  setOptionValue(value?: string) {
    this._stateSubject.next({
      ...this.state,
      selectedValue: value
    });
    console.log('selectedValue store change');
  }

  saveCanvas(canvas) {
    this._stateSubject.next({
      ...this.state,
      canvasEL: canvas
    });
  }

  createBard(data: any): Observable<any> {
    return this.http.post('https://api.board.dev.comodr.com/jsonapi/board/', {
      data: {
        type: "node--board",
        attributes: {
          title: `${data.directoryName}\/${data.graphName}`,
          board_data: data,
        }
      }
    });
  }

  getBoardById(id): Observable<any> {
    return this.http.get(`https://api.board.dev.comodr.com/jsonapi/board/${id}`) as Observable<any>;
  }

  editBoards(data, id) {
    return this.http.patch(`https://api.board.dev.comodr.com/jsonapi/board/${id}`, {
      data: {
        type: "node--board",
        id: id,
        attributes: {
          title: `${data.directoryName}\/${data.graphName}`,
          board_data: data,
        }
      }
    });
  }

  setGraphStatus(value) {
    this._stateSubject.next({
      ...this.state,
      graphReady: value,
    });
  }
}
