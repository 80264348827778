import { Injectable } from '@angular/core';

declare var mxConstants: any;
declare var mxObjectCodec: any;
declare var mxCodecRegistry: any;
declare var mxUtils: any;


@Injectable({
  providedIn: 'root'
})
export class DataCodecService {

  constructor() { }

  initCodec(constructor) {

    const codec = new mxObjectCodec(new constructor(null));

    codec.encode = (enc, obj) => {
      const node = enc.document.createElement('CustomData');
      mxUtils.setTextContent(node, JSON.stringify(obj));
      return node;
    };

    codec.decode = (dec, node , into) => {
      const obj = JSON.parse(mxUtils.getTextContent(node));
      obj.constructor = constructor;

      return obj;

    };

    mxCodecRegistry.register(codec);
  }


}
