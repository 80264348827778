import { Component, OnInit, OnDestroy, ElementRef, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { MxGraphGlobalStoreService, IProjectState } from '../../mxgraph-global.store';
import { Subscription } from 'rxjs';
import { EventEmitterService } from '../../services/event-emmiter.service';
import { SideNavsService } from '../../services/side-navs.service';
import { MatSidenav } from '@angular/material';
import { LocalStorageService } from '../../services/storage-service.service';
import {EventEmitterType} from '../../interfaces/event-emitter.interface';
import {KeyEventService} from '../../services/key-event.service';
import {HistoryService} from '../../services/history.service';
import {ZoomControlService} from '../../services/zoom-control.service';
import {ZoomValue} from '../../interfaces/zoom-control.interface';
import {DataCodecService} from "../../services/data-codec.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {GraphService} from '../../services/graph.service';
import {arrowRightStylesIcons, arrowLeftStylesIcons, arrowLineStyle} from '../../controlsForFigure/arrowsControls';
import {CreateFigureService} from '../../services/create-figure.service'


declare var mxGraph: any;
declare var mxCodec: any;
declare var mxConstants: any;
declare var mxConnectionConstraint: any;
declare var mxPolyline: any;
declare var mxShape: any;
declare var mxVertexHandler: any;
declare var mxPerimeter: any;
declare var mxClient: any;
declare var mxUtils: any;
declare var mxEvent: any;
declare var mxGraphView: any;
declare var mxKeyHandler: any;
declare var mxUndoManager: any;
declare var mxCell: any;
declare var mxObjectCodec: any;
declare var mxGraphHandler: any;
declare var mxEditor: any;
declare var mxDefaultToolbar: any;
declare var mxToolbar: any;
export enum KEY_CODE {
  WHITESPACE_KEY = 32
}

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit, AfterViewInit, OnDestroy {

  subs: Subscription[] = [];
  graphs;
  model;
  parent;
  isPan = false;
  selected;
  canvas: any;
  zoomValue = ZoomValue;
  graphId: string;
  canSave: boolean = false;

  @ViewChild('board', {static: false}) board: ElementRef;

  @ViewChild('sidenav', {static: false}) public sidenav: MatSidenav;

  constructor(private globals: MxGraphGlobalStoreService,
              private eventEmitterService: EventEmitterService,
              private sideNavsService: SideNavsService,
              private localStorageService: LocalStorageService,
              private keyEventService: KeyEventService,
              private historyService: HistoryService,
              private zoomControlService: ZoomControlService,
              private dataCodecService: DataCodecService,
              private activatedRoute: ActivatedRoute,
              private graphService: GraphService,
              private router: Router,
              private createFigureService: CreateFigureService) { }


  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.type === 'keypress' && event.keyCode === KEY_CODE.WHITESPACE_KEY) {
      this.localGraph.graph.panningHandler.useLeftButtonForPanning = true;
    }

    if (event.type === 'keyup' && event.keyCode === KEY_CODE.WHITESPACE_KEY) {
      this.localGraph.graph.panningHandler.useLeftButtonForPanning = false;
      this.isPan = false;

    }
    this.keyEventControl(event.keyCode, event.shiftKey, event.ctrlKey);
  }

  ngOnInit() {
    this.subs.push(
      this.globals.state$.subscribe((state: IProjectState) => {
        this.graphs = state.graphs;
        this.parent = state.parent;
        this.selected = state.selectedValue;
      }),

      this.eventEmitterService.emitter.subscribe((type) => {
          switch (type) {
            case EventEmitterType.UNDO:
              this.setUpUndoManager();
              break;
            case EventEmitterType.FORWARD:
            case EventEmitterType.BACK:
              this.undoControls(type);
              break;
            case EventEmitterType.CREATE_NEW:
              this.initGraph();
              break;

          }
      }),
      this.eventEmitterService.emitter.subscribe((type) => {
        if (type === EventEmitterType.STYLES) {
          this.setDefaultStyles(this.localGraph.graph);
          this.initConstraints(this.localGraph.graph);
          this.localGraph.graph.setConnectable(true);
          this.localGraph.graph.setPanning(true);
          this.initRemoveControl();

          this.localGraph.graph.addListener(mxEvent.CLICK,  (sender, evt) => {
            let cell = evt.getProperty('cell');
            if (cell !== null && this.localGraph.graph.container) {
              this.sideNavsService.close();
            }
          });

          let rubberBand = new mxRubberband(this.localGraph.graph);
          let keyHandler = new mxKeyHandler(this.localGraph.graph);
        }
      }),

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.initGraph();
        }
      })
    );
  }



  initGraph() {

    this.graphService.clearGraph();
    const graph = this.graphService.getNewGraph(document.getElementById('board'));

    // default setting for graph cell
    this.graphService.defaultGraphInit(graph);

    this.subs.push(
      this.globals.getBoardById(this.activatedRoute.snapshot.paramMap.get('id')).subscribe(
        res => {
          const xml = res.data.attributes.board_data;
          const doc = mxUtils.parseXml(xml.graph);
          const dec = new mxCodec(doc);
          dec.decode(doc.documentElement, graph.model);
          const graphObj = Object.assign({graph, graphId: res.data.id, graphName: xml.graphName, directoryName: xml.directoryName, graphBg: xml.graphBg, graphZoom: xml.graphZoom, canvasZoom: xml.canvasZoom}, null);
          this.globals.saveGraphItem(graphObj);
          this.activeOption = xml.graphName;
          this.selected = xml.graphName;
          this.globals.saveProjectName(xml.directoryName);
          this.localGraph.graphZoom = xml.graphZoom;
          this.localGraph.graphBg = xml.graphBg;
          this.localGraph.canvasZoom = xml.canvasZoom;
          this.localGraph.uploadFile = xml.fileUpload;
          this.localGraph.graph.zoom(xml.graphZoom, true);

          this.localGraph.graph.view.validate();
          this.init();

          this.localStorageService.saveGraphList({id: res.data.id, name: xml.graphName});
          this.globals.setGraphStatus(true);
        },
        error => {
          this.graphService.createGraph();
        })
    );
  }


  ngAfterViewInit(): void {
    this.initGraph();

    const that = this;
    const mxGraphViewValidateBackground = mxGraphView.prototype.validateBackground;
    mxGraphView.prototype.validateBackground = function() {
      mxGraphViewValidateBackground.apply(this, arguments);
      if (that.localGraph) {
        that.saveBoardToLocalStorage();
      }
    };
  }


  saveBoardToLocalStorage() {
    this.debounceSave(500);
  }

  debounceSave(ms) {
    if (this.canSave) return;
    this.globals.editBoards({
      graph: this.graphService.convertToXml(this.localGraph.graph),
      graphId: this.localGraph.graphId,
      directoryName: this.directoryNameInStore,
      graphName: this.activeOption,
      graphBg: this.localGraph.graphBg,
      graphZoom: this.localGraph.graphZoom,
      canvasZoom: this.localGraph.canvasZoom,
      fileUpload: this.localGraph.uploadFile ? this.localGraph.uploadFile : null
    }, this.localGraph.graphId).subscribe();

    this.canSave = true;

    setTimeout(() => this.canSave = false, ms);
  }

  get directoryNameInStore() {
    return this.globals.state.projectName;
  }

  get localGraph(): any {
    return this.globals.state.graphs.find((graph) => graph.graphName === this.selected);
  }

  set localGraph(graph) {
    this.globals.saveGraphItem(graph);
  }

  get activeOption(): any {
    return this.globals.state.selectedValue;
  }

  set activeOption(selected) {
    this.globals.setOptionValue(selected);
  }

  init() {
    this.setUpCanvas();
    this.setUpImgStore();
    this.initConstraints(this.localGraph.graph);
    this.initRemoveControl();
    this.setUpUndoManager();


    this.localGraph.graph.addListener(mxEvent.CLICK,  (sender, evt) => {
      let cell = evt.getProperty('cell');
      if (cell !== null && this.localGraph.graph.container) {
        this.sideNavsService.close();
      }
    });
    this.localGraph.graph.panningHandler.previewEnabled  = false;
  }

  keyEventControl(keyCode, shiftHold, ctrlHold): void {
    if (this.localGraph.graph.getSelectionCell() && !this.localGraph.graph.cellEditor.textarea) {
      this.localGraph.graph.getModel().beginUpdate();
      this.keyEventService.keyEventControls(this.localGraph.graph, keyCode, shiftHold, ctrlHold, (this.canvas as HTMLElement).getBoundingClientRect());
      this.localGraph.graph.getModel().endUpdate();
    }
  }

  setUpCanvas() {
    const canvas = document.createElement('canvas');
    canvas.setAttribute('id', 'canvas');
    canvas.style.position = 'absolute';
    canvas.style.top = '0px';
    canvas.style.left = '0px';
    canvas.style.zIndex = '-1';
    canvas.style.backgroundColor = '#f4f4f5';

    this.board.nativeElement.appendChild(canvas);
    this.canvas = canvas;
    this.globals.saveCanvas(canvas);
    (window as any).canvasGraph = canvas;

    const boardBounds = this.board.nativeElement.getBoundingClientRect();

    canvas.setAttribute('width', boardBounds.width.toString());
    canvas.setAttribute('height', boardBounds.height.toString());

    const mxGraphViewIsContainerEvent = mxGraphView.prototype.isContainerEvent;
    mxGraphViewIsContainerEvent.prototype.isContainerEvent = function(evt) {
      return mxGraphViewIsContainerEvent.apply(this, arguments) ||
        mxEvent.getSource(evt) === canvas;
    };
  }

  setUpImgStore() {
    const store = document.createElement('div');
    store.setAttribute('id', 'img-wrapp');
    store.setAttribute('style', '  width: 100%;\n' +
      '  height: 100%;\n' +
      '  position: absolute;\n' +
      '  top: 0;\n' +
      '  left: 0;\n' +
      '  z-index: -1;' +
      // 'background-color: #f8f8f8;' +
      'background-repeat: no-repeat;\n' +
      'background-position: center center;\n' +
      'background-size: contain;');
    this.board.nativeElement.appendChild(store);
  }

  initConstraints = (graph) => {

    mxPolyline.prototype.constraints = null;

    graph.getAllConnectionConstraints = (terminal, source) => {
      if (terminal != null && terminal.shape != null) {
        if (terminal.shape.stencil != null) {
          return terminal.shape.stencil.constraints;
        }
        if (terminal.shape.constraints != null) {
          return terminal.shape.constraints;
        }
      }

      return null;
    };

    mxShape.prototype.constraints = [new mxConnectionConstraint(new mxPoint(0.25, 0), true),
      new mxConnectionConstraint(new mxPoint(0.75, 0), true),
      new mxConnectionConstraint(new mxPoint(0, 0.25), true),
      new mxConnectionConstraint(new mxPoint(0, 0.75), true),
      new mxConnectionConstraint(new mxPoint(1, 0.25), true),
      new mxConnectionConstraint(new mxPoint(1, 0.75), true),
      new mxConnectionConstraint(new mxPoint(0.25, 1), true),
      new mxConnectionConstraint(new mxPoint(0.75, 1), true)];
  }

  setDefaultStyles(graph) {
    const objStyle = new Object(),
          objTriangleStyle = new Object(),
          objRectangleStyle = new Object(),
          subjStyle = new Object(),
          edgeStyle = graph.getStylesheet().getDefaultEdgeStyle(),
          textStyle = new Object(),
          groupStyle = new Object();

    objStyle[mxConstants.ROUNDED] = true;
    objStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_ELLIPSE;
    objStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    objStyle[mxConstants.STYLE_FONTSIZE] = '18';
    objStyle[mxConstants.STYLE_VERTICAL_ALIGN] = 'bottom';
    objStyle[mxConstants.STYLE_FILLCOLOR] = '#2f2f2f';
    graph.getStylesheet().putCellStyle('objEllipse', objStyle);

    objTriangleStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_IMAGE;
    objTriangleStyle[mxConstants.STYLE_IMAGE] = '/assets/triangle.svg';
    objTriangleStyle[mxConstants.STYLE_FILLCOLOR] = '#2f2f2f';
    objTriangleStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    objTriangleStyle[mxConstants.STYLE_FONTSIZE] = '18';

    graph.getStylesheet().putCellStyle('objTriangle', objTriangleStyle);

    objRectangleStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
    objRectangleStyle[mxConstants.STYLE_FILLCOLOR] = '#2f2f2f';
    objRectangleStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    objRectangleStyle[mxConstants.STYLE_FONTSIZE] = '18';

    graph.getStylesheet().putCellStyle('objRectangle', objRectangleStyle);

    subjStyle[mxConstants.STYLE_PERIMETER] = mxPerimeter.RectanglePerimeter;
    subjStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_IMAGE;
    subjStyle[mxConstants.STYLE_IMAGE] = '/assets/man.svg';
    subjStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    subjStyle[mxConstants.STYLE_FONTSIZE] = '18';
    graph.getStylesheet().putCellStyle('man', subjStyle);

    let subjStyle2 = Object.assign({}, subjStyle);
    let subjStyle3 = Object.assign({}, subjStyle);
    subjStyle2[mxConstants.STYLE_IMAGE] = '/assets/woman.svg';
    subjStyle3[mxConstants.STYLE_IMAGE] = '/assets/cooldude.svg';
    graph.getStylesheet().putCellStyle('woman', subjStyle2);
    graph.getStylesheet().putCellStyle('glassesMan', subjStyle3);

    edgeStyle[mxConstants.STYLE_FONTSIZE] = '18';
    edgeStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    edgeStyle[mxConstants.STYLE_EDGE]  = mxConstants.EDGESTYLE_ORTHOGONAL;
    edgeStyle[mxConstants.STYLE_STROKEWIDTH] = 2;
    edgeStyle[mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = 'transparent';
    edgeStyle[mxConstants.STYLE_VERTICAL_ALIGN] = 'top';
    edgeStyle[mxConstants.STYLE_ROUNDED] = true;

    graph.getStylesheet().putCellStyle('edgeS', edgeStyle);


    textStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    textStyle[mxConstants.STYLE_FONTSIZE] = '18';
    textStyle[mxConstants.STYLE_VERTICAL_ALIGN] = 'center';
    textStyle[mxConstants.STYLE_FILLCOLOR] = 'transparent';
    textStyle[mxConstants.STYLE_AUTOSIZE] = true;

    graph.getStylesheet().putCellStyle('customText', textStyle);

    groupStyle[mxConstants.STYLE_SHAPE] = mxConstants.SHAPE_RECTANGLE;
    groupStyle[mxConstants.STYLE_FONTCOLOR] = 'black';
    groupStyle[mxConstants.STYLE_FONTSIZE] = '19';
    groupStyle[mxConstants.STYLE_FILLCOLOR] = '#39aad647';

    graph.getStylesheet().putCellStyle('group', groupStyle);

  }

  zoom(factor) {
    this.localGraph.graph.getModel().beginUpdate();
    try {
      this.zoomControlService.onZooming(factor, this.localGraph.graph);
      this.zoomControlService.scaleCanvasBg(this.localGraph, factor, this.canvas);
    } finally {
      this.localGraph.graphZoom = this.zoomControlService.getCurrentZoomValue(this.localGraph.graph);
      this.localGraph.graph.getModel().endUpdate();
    }
  }

  onResize(event) {
    const boardRect = this.board.nativeElement.getBoundingClientRect();
    this.canvas.setAttribute('width', boardRect.width.toString());
    this.canvas.setAttribute('height', boardRect.height.toString());
    this.eventEmitterService.invokeComponentAction(EventEmitterType.BG);
  }

  togglePanning() {
    const board: HTMLElement = document.querySelector('#board') as HTMLElement;
    this.isPan = !this.isPan;
    if (this.isPan) {
      this.localGraph.graph.panningHandler.useLeftButtonForPanning = true;

      this.localGraph.graph.panningHandler.addListener(mxEvent.PAN_START, (sender, evt) => {
        board.style.cursor = 'move';
        evt.consume();
      });

      this.localGraph.graph.panningHandler.addListener(mxEvent.PAN_END, (sender, evt) => {
        board.style.cursor = 'default';
        evt.consume();
      });
    } else {
      this.localGraph.graph.panningHandler.useLeftButtonForPanning = false;
    }
  }

  initRemoveControl() {
    this.localGraph.graph.createHandler = function(state) {
      if (state != null && this.model.isVertex(state.cell)) {
        return new MxVertexToolHandler(state);
      }
      return mxGraph.prototype.createHandler.apply(this, arguments);
    };

    function MxVertexToolHandler(state) {
      mxVertexHandler.apply(this, arguments);
    }

    MxVertexToolHandler.prototype = new mxVertexHandler();
    MxVertexToolHandler.prototype.constructor = MxVertexToolHandler;

    MxVertexToolHandler.prototype.domNode = null;

    const that = this;

    MxVertexToolHandler.prototype.init = function()  {
      mxVertexHandler.prototype.init.apply(this, arguments);

      this.domNode = document.createElement('div');
      this.domNode.className = 'domNode';
      this.domNode.style.position = 'absolute';
      this.domNode.style.whiteSpace = 'nowrap';


      function createImage(src) {
        if (mxClient.IS_IE && !mxClient.IS_SVG) {
          let img = document.createElement('div');
          img.style.backgroundImage = 'url(' + src + ')';
          img.style.backgroundPosition = 'center';
          img.style.backgroundRepeat = 'no-repeat';
          img.style.display = (mxClient.IS_QUIRKS) ? 'inline' : 'inline-block';
          return img;
        } else {
          return mxUtils.createImage(src);
        }
      }

      const deleteIcon = createImage('/assets/delete.svg');
      deleteIcon.setAttribute('title', 'Delete');
      deleteIcon.style.cursor = 'pointer';
      deleteIcon.style.width = '36px';
      deleteIcon.style.height = '36px';
      mxEvent.addGestureListeners(deleteIcon,
        mxUtils.bind(this, (evt) => {
          // Disables dragging the image
          mxEvent.consume(evt);
        })
      );
      mxEvent.addListener(deleteIcon, 'click',
        mxUtils.bind(this, (evt) => {
          this.graph.removeCells([this.state.cell]);
          mxEvent.consume(evt);
        })
      );
      mxEvent.addListener(deleteIcon, 'touch',
        mxUtils.bind(this, (evt) => {
          this.graph.removeCells([this.state.cell]);
          mxEvent.consume(evt);
        })
      );
      const settingIcons = createImage('/assets/gear.svg');
      settingIcons.setAttribute('title', 'setting');
      settingIcons.style.cursor = 'pointer';
      settingIcons.style.width = '36px';
      settingIcons.style.height = '36px';
      settingIcons.style.position = 'absolute';
      settingIcons.style.bottom = '-60px';
      settingIcons.style.left = '2px';
      mxEvent.addGestureListeners(settingIcons,
        mxUtils.bind(this, (evt) => {
          // Disables dragging the image
          mxEvent.consume(evt);
        })
      );
      mxEvent.addListener(settingIcons, 'touch', (e) => {
        e.stopPropagation();
        that.eventEmitterService.invokeComponentAction(EventEmitterType.OPEN_SETTING);
        });
      mxEvent.addListener(settingIcons, 'click', (e) => {
        e.stopPropagation();
        that.eventEmitterService.invokeComponentAction(EventEmitterType.OPEN_SETTING);
        });
      this.domNode.appendChild(deleteIcon);
      this.domNode.appendChild(settingIcons);

      this.graph.container.appendChild(this.domNode);
      this.redrawTools();
    }

    MxVertexToolHandler.prototype.redraw = function() {
      mxVertexHandler.prototype.redraw.apply(this);
      this.redrawTools();
    };

    MxVertexToolHandler.prototype.redrawTools = function() {
      if (this.state != null && this.domNode != null) {
        let dim = 70;
        if (this.state.height > 50) {
          dim = 35 + this.state.height;
        }
        this.domNode.style.left = (this.state.x + this.state.width + 5) + 'px';
        this.domNode.style.top = (this.state.y + this.state.height - dim) + 'px';
      }
    };

    MxVertexToolHandler.prototype.destroy = function(sender, me) {
      mxVertexHandler.prototype.destroy.apply(this, arguments);

      if (this.domNode != null) {
        this.domNode.parentNode.removeChild(this.domNode);
        this.domNode = null;
      }
    };
  }

  setUpUndoManager() {
    this.historyService.createHistoryManager();

    const listener = (sender, event) => {
      this.historyService.saveHistory(event.getProperty('edit'));
    };

    this.localGraph.graph.getModel().addListener(mxEvent.UNDO, listener);
    this.localGraph.graph.getView().addListener(mxEvent.UNDO, listener);

    const keyHandler = new mxKeyHandler(this.localGraph.graph);

    keyHandler.bindControlKey(90, (evt) => {
      this.historyService.undo();
    });
    keyHandler.bindControlShiftKey(90, (evt) => {
      this.historyService.redo();
    });
  }

  undoControls(type) {
    if (type === 'forward') {
      this.historyService.redo();
    } else {
      this.historyService.undo();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
